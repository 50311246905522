/* Global settings for vue. */
import "./vendor/css/bootstrap-grid.min.css";
import "./components/core/common.less";

import Vue from "vue";
import { isFunction } from "lodash";
import * as querystring from "querystring";
import devToolsMixin from "@/lib/dev-tools-mixin.js";
import Config from "@/lib/config.js";
import ScreenControl from "@/lib/screen-control.js";
import ScreenActivity from "@/lib/screen-activity.js";
import { default as l10n, load as loadLocalization } from "@/lib/localization/localization.js";
import setEasyscreenLocalization from "@/lib/localization/moment-locale.js";
import Skin from "@/lib/skin.js";
import VuexLike from "@/lib/vuex-like.js";
import Request from "@/lib/request/request.js";
import EasyscreenManagerStatistic from "@/lib/easyscreen-manager-statistic.js";
import CanvasOrientation from "@/lib/canvas-orientation.js";
import easyscreenInfo from "@/lib/easyscreen-info.js";
import asyncTimeout from "@/lib/utils/async-timeout.js";
import appStore from "./store.js";
import FriendlyFrankCore from "./friendly-frank/core.js";
import FriendlyFrankScanner from "./friendly-frank/scanner.js";
import FriendlyFrankPrinter from "./friendly-frank/printer.js";
import FriendlyFrankSortingBin from "./friendly-frank/sorting-bin.js";
import FriendlyFrankBarcodeScanner from "./friendly-frank/barcode.js";

import "./components/core/directives/image-onready.js";
import EasyscreenMenu from "./components/easyscreen-menu/easyscreen-menu.vue";
import WelcomeScreen from "./components/welcome-screen/welcome-screen.vue";
import PresentationSet from "./components/presentation-set/presentation-set.vue";
import CanvasControl from "./components/canvas-control/canvas-control.vue";
import FullscreenButton from "./components/fullscreen-button/fullscreen-button.vue";

(async function() {
  const config = new Config();
  await config.fetch("/client-config.json");
  config.set("defaultDesign", config.get("defaultDesign") || "classic");

  if (config.debug.serverLogs) {
    await import("@/lib/dev-server-logs.js");
  }

  let screenOptions = {};
  const request = new Request({
    easyscreenManager: {
      domain: config.get("manager.domain")
    },
    lmsConnector: {
      l10n: l10n,
      domain: config.get("lmsConnector.url"),
      coversDomain: config.get("lmsConnector.covers"),
      get loanWithoutPin() { return config.get("enable.loanWithoutPin"); },
      get screenOptions() { return screenOptions; }
    },
    wayfinder: {
      domain: config.get("wayfinder.url")
    },
    friendlyFrank: {
      domain: config.get("scanner.http")
    }
  });

  let friendlyFrankScanner;
  let friendlyFrankSortingBin;
  /* The 'soket' - fallback for wrong option naming for old clients. */
  if (["socket", "soket", "keyboard-emitter"].includes(config.get("scanner.type"))) {
    friendlyFrankScanner = new FriendlyFrankScanner({
      easyscreenConfig: config,
      type: config.get("scanner.type"),
      uri: config.get("scanner.ws"),
      withExternalResources: config.get("enable.externalResources") || config.get("enable.videosFromYT"),
      patternOptions: config.get("scanner.material"),
      expireDelay: config.get("scanner.expireDelay"),
      request: request.lmsConnector
    });

    friendlyFrankSortingBin = new FriendlyFrankSortingBin({
      easyscreenConfig: config,
      type: config.get("scanner.type"),
      uri: config.get("scanner.ws"),
      request: request.lmsConnector
    });
  }

  let friendlyFrankPrinter;
  let friendlyFrankBarcodeScanner;
  let friendlyFrankCore;
  if (config.get("scanner.ws")) {
    const friendlyFrankOptions = {
      easyscreenConfig: config,
      uri: config.get("scanner.ws"),
      request: request.lmsConnector
    };

    friendlyFrankCore = new FriendlyFrankCore(friendlyFrankOptions);
    friendlyFrankPrinter = new FriendlyFrankPrinter(friendlyFrankOptions);
    friendlyFrankBarcodeScanner = new FriendlyFrankBarcodeScanner(friendlyFrankOptions);
  }

  await request.ready();

  const urlOptions = querystring.parse(window.location.href.split("#")[1]);
  if (urlOptions.bodyBackground) {
    document.querySelector("html").style.background = urlOptions.bodyBackground;
  }

  let scaleMode = "";
  let scaleTarget = null;

  const isPreviewMode = "pageId" in urlOptions;
  if ("fit" in urlOptions) {
    scaleMode = "fit";
    scaleTarget = config.get("fit.orientation") || { width: 1920, height: 1080 };
  } else if (isPreviewMode) {
    scaleMode = "manual";
    scaleTarget = config.get("fit.orientation") || { width: 1080, height: 1920 };
  } else if (config.get("debug.enable") === true) {
    VuexLike.historyEnabled = config.get("debug.vuexLikeHistoryEnabled") || false;
    scaleMode = "manual";
    scaleTarget = config.get("debug.canvas.orientation");
  }

  const canvasOrientation = new CanvasOrientation({
    el: document.querySelector("body"),
    mode: scaleMode,
    scaleTarget: scaleTarget
  });

  const screenId = urlOptions.screenId || urlOptions.id;
  const easyscreenManagerStatistic = new EasyscreenManagerStatistic({
    $request: request.easyscreenManager,
    screenId: screenId,
    permanentDisable: urlOptions.statistic === "off"
  });

  const skin = new Skin({ skin: config.skin });
  const localizationService = {
    domain: config.get("lmsConnector.domain")
  };
  const defaultLocalizationProject = urlOptions["localization.project"] || config.get("lmsConnector.l10n.project");
  const defaultLocalizationLanguage = urlOptions["localization.language"] || config.get("lmsConnector.l10n.language");
  const defaultLocale = `${ defaultLocalizationProject }-${ defaultLocalizationLanguage }`;

  Vue.mixin(devToolsMixin);

  Vue.config.productionTip = false;
  Vue.prototype.winproxy = window;

  setEasyscreenLocalization(defaultLocale, localizationService);

  /* ---- START:DEFAULT PAGE BEHAVIOUR ---- */
  window.onhashchange = function() {
    window.location.reload();
  };

  document.oncontextmenu = function(event) {
    if (event.preventDefault != undefined) {
      event.preventDefault();
    }
    if (event.stopPropagation != undefined) {
      event.stopPropagation();
    }
  };
  /* ---- END:DEFAULT PAGE BEHAVIOUR ---- */

  /* ---- START:SCREEN ACTIVITY INITIALIZATION ---- */
  const screenActivity = new ScreenActivity({
    pendingTime: urlOptions.standBy || config.waitForUserAction,
    dontOverwrite: Boolean(urlOptions.standBy)
  });
  /* Handler for ereolen proxy, see /routes/proxy/ereolen-proxy.js. */
  window.screenActivity = screenActivity;

  if (urlOptions.standBy !== "off") {
    screenActivity.enable(true);
    screenActivity.run();
  }

  /* Default user actions for pending. */
  window.addEventListener("mousedown", () => {
    screenActivity.action();
  });
  window.addEventListener("touchstart", () => {
    screenActivity.action();
  });
  window.addEventListener("keydown", () => {
    screenActivity.action();
  });

  window.focus();
  window.addEventListener("blur", function() {
    if (document.activeElement.nodeName === "IFRAME") {
      screenActivity.action();
    }
  });
  /* ---- END:SCREEN ACTIVITY INITIALIZATION ---- */

  /* ---- START:SCREEN CONTROL INITIALIZATION (APP ENTRY) ---- */
  /* Require the initialized screen activity instance for pause and restore data fetch loop. */
  let screenControl;
  Vue.mixin({
    beforeCreate() {
      /* Define app config */
      this.$easyscreenNativeScrollable = false;
      this.$easyscreenConfig = config;
      this.$easyscreenScreenId = screenId;
      this.$easyscreenIsMobile = false;

      /* Define the app localization method. */
      this.$l10n = l10n;
      this.$easyscreenDefaultLocale = defaultLocale;
      this.$easyscreenLoadLocalization = loadLocalization;

      /* Define the app screen activity methods. */
      this.$easyscreenScreenActivity = screenActivity;

      /* Define the skin. */
      this.$easyscreenSkin = skin;

      /* Define the request modules */
      this.$easyscreenRequest = request;
      this.$friendlyFrankScanner = friendlyFrankScanner;
      this.$friendlyFrankSortingBin = friendlyFrankSortingBin;
      this.$friendlyFrankCore = friendlyFrankCore;
      this.$friendlyFrankPrinter = friendlyFrankPrinter;
      this.$friendlyFrankBarcodeScanner = friendlyFrankBarcodeScanner;

      /* Define statistic module */
      this.$easyscreenStatistic = easyscreenManagerStatistic;

      /* Define canvas orientation. */
      this.$easyscreenCanvasOrientation = canvasOrientation;

      /* Define text scale. */
      this.$easyscreenScale = screenOptions.scale;

      /* Define link to screen control instance. */
      this.$easyscreenScreenControl = screenControl;
    },
    async mounted() {
      if (isFunction(this.$options.screenStandby)) {
        await asyncTimeout(500);
        if (this.$easyscreenScreenActivity.state === "running" && this._isDestroyed === false) {
          /*
           * The timeout are required for correct element css calculations on
           * first load of widget to wait when the css styles will be applied.
           */
          this.$options.screenStandby.call(this);
        }

        this.$once("hook:beforeDestroy", this.$easyscreenScreenActivity.on("running", () => {
          this.$options.screenStandby.call(this);
        }));
      }

      if (isFunction(this.$options.screenActive)) {
        this.$once("hook:beforeDestroy", this.$easyscreenScreenActivity.on("pending", () => {
          this.$options.screenActive.call(this);
        }));
      }
    },
    beforeDestroy() {
      if (isFunction(this.$options.screenActive)) {
        this.$options.screenActive.call(this);
      }
    }
  });

  const canvasControl = new (Vue.extend(CanvasControl))({
    propsData: {
      canvasRotate: config.get("debug.enable") === true || isPreviewMode,
      canvasMove: config.get("debug.canvas.move") === true,
      canvasScale: config.get("debug.canvas.scale") === true
    }
  });
  const canvasControlContainer = document.querySelector(".easyscreen-canvas-control");
  /* Move the canvas control container out of body to prevent the scale and translation effects. */
  document.querySelector("html").appendChild(canvasControlContainer);
  canvasControl.$mount(canvasControlContainer);

  const fullscreenButton = new (Vue.extend(FullscreenButton))();
  fullscreenButton.$mount(document.querySelector(".easyscreen-fullscreen-button"));

  screenControl = new ScreenControl({
    fetchContent: request.easyscreenManager.content,
    fetchPreview: request.easyscreenManager.preview,
    lmsPing: request.lmsConnector.ping,
    config: {
      attemptsBetweenTimeout: config.get("manager.attemptsBetweenTimeout"),
      timeoutBetweenAttempts: config.get("manager.timeoutBetweenAttempts"),
      updateInterval: config.get("manager.updateInterval"),
      invetralBetweenAttempts: config.get("manager.invetralBetweenAttempts"),
      lmsPingInterval: config.get("safetyMode.checkingInterval")
    },
    mountTo: document.querySelector(".easyscreen-client"),
    screens: {
      presentationSet: () => new (Vue.extend(PresentationSet))({
        store: appStore,
        propsData: { slides: [] }
      }),
      welcomeScreen: () => new (Vue.extend(WelcomeScreen))({
        store: appStore
      }),
      easyscreenMenu: () => new (Vue.extend(EasyscreenMenu))({
        store: appStore
      })
    }
  });

  screenControl.on("before-screen-update", async (screenData, screenName) => {
    /* Set default standby for welcome screen. */
    if (screenName === "welcomeScreen") {
      screenActivity.changePendingTime(60 * 1000);
    }

    /* Reset all screen features. */
    Object.keys(config.get("enable")).forEach(feature => {
      config.set(`enable.${ feature }`, false);
    });

    /* Set the new screen features. */
    ScreenControl.getActiveFeatures(screenData).forEach(activeFeature => {
      config.set(`enable.${ activeFeature }`, true);
    });

    /* Define the screen options. */
    screenOptions = {
      scale: 100,
      ...(screenData.screenOptions || {})
    };
    /* Convert sip2 options from legacy format to modern (will be used from manager v3.3). */
    if (!("sip2" in screenOptions)) {
      screenOptions.sip2 = {
        profile: screenOptions.sip2_profile,
        defaultDeliveryMessage: screenOptions.defaultDeliveryMessage,
        deliveryToSortingBinMessage: screenOptions.deliveryToSortingBinMessage
      };
    }

    /* Load default localization. */
    await loadLocalization(defaultLocale, localizationService);
    setEasyscreenLocalization(defaultLocale, localizationService);
  });

  /* Disabling screen updates while screen using. */
  screenActivity.on("running", function() {
    screenControl.unpauseUpdates();
    easyscreenManagerStatistic.disableStatistic();
  });
  screenActivity.on("pending", function() {
    screenControl.pauseUpdates();
    easyscreenManagerStatistic.enableStatistic();
  });

  window.request = request;
  window.screenControl = screenControl;
  /* ---- END:SCREEN CONTROL INITIALIZATION (APP ENTRY) ---- */

  /* ---- START:FALLBACK FOR PREVIOUS IMPLEMENTATION. ---- */
  window.standBy = screenActivity;
  window.l10n = l10n;
  window.ES_SKIN = skin;
  window.ESCONFIG = config;
  /* ---- END:FALLBACK FOR PREVIOUS IMPLEMENTATION. ---- */

  /* ---- START:GLOBAL VARIABLES FOR DEBUGING NEEDS. ---- */
  if (config.get("debug.enable") === true) {
    document.body.classList.add("easyscreen_debug-enabled");
    window.appStore = appStore;
  }
  /* ---- END:GLOBAL VARIABLES FOR DEBUGING NEEDS. ---- */

  easyscreenInfo(config);
})();
