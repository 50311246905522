<template>
  <modal-layout-navigation
    ref="screenNavigation"
    class="visual-shelf"
    :title="$easyscreenConfig.get('library.name', $easyscreenConfig.name)"
    :withSearch="hasPromotedMaterials"
    :searchDisabled="searchShown"
    :wayfinder-data="wayfinderData"
    :disable-navigation="disableNavigation"
    @show-search="_showSearch"
  >
    <inspiration-scanner
      v-if="hasPromotedMaterials"
      ref="promoted"
      class="visual-shelf--promoted-carousel"
      position="absolute"
      :title="title"
      :with-navigation-controls="true"
      :use-scanner="false"
      :with-scanner-hint="false"
      :design="design"
      :promoted-materials="promotedMaterials"
      :forced-special-color="promotedMaterialsBackgroundColor"
      @select-tag="_selectTag"
      @modal-opened="_handlePromotedOpenedModal"
      @closed-inner-modal="_handlePromotedClosedModal"
    />

    <component
      ref="search"
      class="visual-shelf--search"
      position="absolute"
      :is="design === 'light' ? 'easyscreen-search-light' : 'easyscreen-search'"
      :available-only-button="true"
      :available-only="true"
      :shelf-only-button="true"
      :shelf-only="true"
      :with-close-controls="false"
      :query-prefix="queryPrefix"
      @opened="searchShown = true"
      @closed="_onSearchClosed"
      @modal-opened="(closeModal, type) => $refs.screenNavigation.pushBackAction(closeModal, type)"
      @before-hot-update="_onHotUpdate"
      @closed-inner-modal="(type) => $refs.screenNavigation.popBackAction(type)"
      @before-search-results-closed="_hideSearch"
    />

    <inspiration-scanner
      v-if="withInspirationScanner"
      ref="inspirationScanner"
      class="visual-shelf--inspiration-carousel"
      position="absolute"
      :title="inspirationScannerTitle"
      :with-navigation-controls="true"
      :with-scanner-hint="false"
      :design="design"
      :with-covers-only="inspirationScannerWithCoversOnly"
      :available-only="inspirationScannerAvailableOnly"
      :department-id="inspirationScannerDepartmentId"
      :branch-id="inspirationScannerBranchId"
      @select-tag="_selectTag"
      @modal-opened="_handleInspirationOpenedModal"
      @closed-inner-modal="_handleInspirationClosedModal"
    />
  </modal-layout-navigation>
</template>

<style src="./visual-shelf.less" lang="less"></style>
<script>
  import ModalLayoutNavigation from "@/components/core/modal/layout-navigation.vue";
  import InspirationScanner from "@/components/inspiration-scanner/inspiration-scanner.vue";

  import EasyscreenSearchLight from "./search-light.vue";
  import EasyscreenSearch from "./search.vue";

  export default {
    name: "visual-search",
    props: {
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      },
      promotedMaterials: {
        type: Array,
        default: () => ([])
      },
      /* Custom static color of the promoted materials carousel (will override any dinamicly computed color). */
      promotedMaterialsBackgroundColor: String,
      /* Flag for find the materials with covers only. */
      inspirationScannerWithCoversOnly: Boolean,
      /* Flag for find the available only materials. */
      inspirationScannerAvailableOnly: Boolean,
      /* Used for prioritize the material holdings from selected department. */
      inspirationScannerDepartmentId: String,
      /* Used for prioritize the material holdings from selected branch. */
      inspirationScannerBranchId: String,
      /* Custom title for the inspiration scanner that replace the `Items which is related to ...`. */
      inspirationScannerTitle: String,
      withInspirationScanner: {
        type: Boolean,
        default: false
      },
      /* The title of search results screen, used for shelfOnly feature. */
      title: String,
      /* The prefix of query which used for shelfOnly feature. */
      queryPrefix: String,
      /* The data for open wayfinder from the bottom navigation. */
      wayfinderData: Object,
      disableNavigation: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      hasPromotedMaterials() {
        return this.promotedMaterials.length !== 0;
      }
    },
    data() {
      return {
        searchShown: false
      };
    },
    methods: {
      /**
       * Initialize the visual shelf search (show the main search screen and perform default search).
       */
      async _showSearch() {
        await this.$refs.search.show();
        this.$refs.search.findBy("");
      },
      _hideSearch() {
        if (this.hasPromotedMaterials)
          return this.$refs.search.hide();
      },
      _onHotUpdate() {
        if (this.hasPromotedMaterials === false)
          setTimeout(this._showSearch, 500);
      },
      async _selectTag(query) {
        await this.$refs.screenNavigation.toHome();
        await this.$refs.search.show();
        this.$refs.search.findBy(query);
      },
      _handlePromotedOpenedModal(closeModal, type) {
        if (type === "inspirationScannerCarousel")
          return;

        this.$refs.screenNavigation.pushBackAction(closeModal, "promoted:" + type);
      },
      _handlePromotedClosedModal(type) {
        this.$refs.screenNavigation.popBackAction("promoted:" + type);
        if (type === "inspirationScannerCarousel")
          this._showSearch();
      },
      _handleInspirationOpenedModal(closeModal, type) {
        this.$refs.screenNavigation.pushBackAction(closeModal, "inspiration:" + type);
      },
      _handleInspirationClosedModal(type) {
        this.$refs.screenNavigation.popBackAction("inspiration:" + type, { findAction: true });
      },
      _onSearchClosed(){
        this.searchShown = false;
        this.$refs.promoted.show();
      }
    },
    async mounted() {
      if (this.hasPromotedMaterials === false)
        this._showSearch();
    },
    components: {
      "modal-layout-navigation": ModalLayoutNavigation,
      "inspiration-scanner": InspirationScanner,
      "easyscreen-search-light": EasyscreenSearchLight,
      "easyscreen-search": EasyscreenSearch
    }
  };
</script>
