<template>
  <div>
    <modal-confirm
      ref="modal"
      :class="['search-facets-modal', orientation]"
      :cancel-options="{
        title: _l10n('Clear all facets'),
        color: 'danger'
      }"
      :position="position"
      :design="design"
      min-width="90%"
      min-height="90%"

      @before-open="(event) => $emit('before-open', event)"
      @opened="(event) => $emit('opened', event)"
      @before-close="(event) => $emit('before-close', event)"
      @closed="(event) => $emit('closed', event)"

      @ok="() => { $emit('selected', selectedFacets) }"
      @cancel="() => { reset(); $emit('selected', {}) }"
    >
      <template slot="header">
        <slot name="title"></slot>

        <easyscreen-circle-button
          v-if="withCloseControls"
          class="search-facets-modal--close-button_top-right"
          icon="/images/es-menu/close_icon.png"
          icon-type="image"

          @click.native="hide"
        />
      </template>
      <template slot="content">
        <scrollable :key="design + orientation" :max-height="(_isLandscape() ? 810 : 1550) - (design === 'light' ? 120 : 0)">
          <div class="search-facets">
            <div
              v-for="facet in facets"
              :key="facet.id"
              class="search-facets-list"
            >
              <div class="search-facets-list--title">
                <span>{{ facet.name }} </span>
                <span v-if="_selectedAmount(facet.id) !== 0">({{ _selectedAmount(facet.id) }})</span>
              </div>
              <div class="search-facets-list--preview">
                <div
                  v-for="value in _getFacetsForPreview(facet)"
                  :key="value.value"
                  class="search-facets-list--preview-element"
                  @click="(event) => {
                    /* Skip the click event by label (checkbox input). */
                    if (!event.defaultPrevented) {
                      _toggleFacet(facet.id, value.value);
                    }
                  }"
                >
                  <easyscreen-checkbox
                    class="search-facets-list--preview-checkbox"
                    :design="design"
                    :checked="_isSelectedFacet(facet.id, value.value)"
                  />
                  <span class="search-facets-list--preview-element_text">{{ value.name }}</span>
                  <span v-if="value.frequence" class="search-facets-list--preview-element_frequence">({{ value.frequence }})</span>
                </div>
              </div>

              <easyscreen-button
                :class="{ 'border-disabled': design === 'light' }"
                :color="design === 'light' ? 'white' : 'gray'"
                :corners="design === 'light' ? 'round' : undefined"
                modificator="full-width"
                @click.native="() => showSingleFacetModal(facet.id)"
              >
                {{ _l10n("Show more") }}
              </easyscreen-button>

              <div class="search-facets-list--delimiter"></div>
            </div>
          </div>
        </scrollable>

        <easyscreen-circle-button
          v-if="withCloseControls"
          class="search-facets-modal--close-button_bottom-right"
          icon="/images/es-menu/close_icon.png"
          icon-type="image"

          @click.native="hide"
        />
      </template>
    </modal-confirm>

    <search-single-facet
      v-if="activeFacetId"
      ref="singleFacetModal"
      :key="activeFacetId"
      :name="_getActiveFacet().name || ''"
      :facet="_getActiveFacet().values || []"
      :selected-default="selectedFacets[activeFacetId] || []"
      :position="position"
      :design="design"
      @opened="(event) => $emit('modal-opened', $refs.singleFacetModal.hide, 'singleFacet')"
      @closed="(event) => {
        $emit('closed-inner-modal', 'singleFacet')
        standaloneSingleFacet = false;
      }"
      @selected="(selected) => {
        selectedFacets[activeFacetId] = selected;
        if (standaloneSingleFacet) {
          $emit('selected', selectedFacets);
        } else {
          $forceUpdate();
        }
      }"
    />
  </div>
</template>

<style src="./search-facets.less" lang="less"></style>
<script>
  import ModalConfirm from "../core/modal/confirm.vue";
  import EasyscreenCheckbox from "../core/input/checkbox.vue";
  import EasyscreenButton from "../core/button/button.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import Scrollable from "../core/scrollable/scrollable.vue";
  import SearchSingleFacet from "./search-single-facet.vue";

  import isElementOf from "@/lib/utils/is-element-of.js";
  import clampNumber from "@/lib/utils/clamp-number.js";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../core/mixins/orientation.js";

  export default {
    name: "search-facets",
    mixins: [
      orientationMixin
    ],
    props: {
      /* The facets of the search. */
      facets: {
        type: Array,
        default: () => ([])
      },
      /* The amount of facet values in preview. Default: 6 */
      previewLimit: {
        type: Number,
        default: 6
      },
      /* The facets selected by default. */
      selectedDefault: {
        type: Object,
        default: () => ({})
      },
      /* Display the close controls in top-right and bottom-right corners. */
      withCloseControls: {
        type: Boolean,
        default: true
      },
      position: String,
      /* The global reskin for materials list. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    watch: {
      selectedDefault(newDefaultFacets) {
        this.selectedFacets = newDefaultFacets;
      }
    },
    data() {
      return {
        activeFacetId: null,
        standaloneSingleFacet: false,
        selectedFacets: this.selectedDefault
      };
    },
    methods: {
      /**
       * Show the current modal.
       *
       * @param {Function} [callback] - The show callback, will be called when modal is opened.
       */
      show(callback) {
        this.$refs.singleFacetModal && this.$refs.singleFacetModal.hide();
        return this.$refs.modal.show({ callback });
      },
      /**
       * Hide the current modal.
       *
       * @param {Function} [callback] - The hide callback, will be called when modal is closed.
       */
      hide(callback) {
        this.$refs.singleFacetModal && this.$refs.singleFacetModal.hide();
        return this.$refs.modal.hide(callback);
      },
      /**
       * Show the modal for single facet.
       * The single facet modal is independend of state for modal with previews.
       *
       * @param {String} facetId - The id of facet.
       * @param {Object} [options] - The options of popup.
       * @param {Boolean} [options.standalone=false] - The standalone modal will emit the "select" event on apply.
       */
      async showSingleFacetModal(facetId, options) {
        options = options || {};

        this.activeFacetId = null;
        await this.$nextTick();
        this.activeFacetId = facetId;
        this.standaloneSingleFacet = options.standalone;
        await this.$nextTick();

        this.$refs.singleFacetModal && this.$refs.singleFacetModal.show();
      },
      /**
       * Reset selected facets.
       */
      reset() {
        this.activeFacetId = null;
        this.selectedFacets = {};
      },
      /**
       * Proxy for isElementOf. See the @/lib/utils/is-element-of.js
       */
      _isElementOf: isElementOf,
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Get the list of facet values for preview.
       *
       * @param {Object} facet - The facet data (from search).
       * @param {String} facet.id - The id of facet.
       * @param {Object[]} facet.values - The facet values.
       * @param {String} facet.values[].value - The value of facet.
       *
       * @returns {String[]} The list of facet values for preview.
       *  Selected first in selection order + regular unselected facet in default order.
       */
      _getFacetsForPreview(facet) {
        let selected = (this.selectedFacets[facet.id] || []).slice(0, this.previewLimit).map((selectedFacetValue) => {
          return facet.values.find(value => value.value === selectedFacetValue);
        }).filter(Boolean);

        let nonSelectedAmount = clampNumber(this.previewLimit - selected.length, 0, Infinity);
        let nonSelected = facet.values.filter(value => {
          return !selected.find(_selected => _selected.value === value.value);
        }).slice(0, nonSelectedAmount);

        return selected.concat(nonSelected) || [];
      },
      /**
       * Select or unselect facet value depends on current state.
       * This method will call the vue $forceUpdate.
       *
       * @param {String} facetId - The id of facet.
       * @param {String} value - The value of facet.
       */
      _toggleFacet(facetId, value) {
        if (!Array.isArray(this.selectedFacets[facetId])) {
          this.selectedFacets[facetId] = [];
        }

        if (this.selectedFacets[facetId].includes(value)) {
          this.selectedFacets[facetId] = this.selectedFacets[facetId].filter(facet => facet !== value);
        } else {
          this.selectedFacets[facetId].push(value);
        }

        this.$forceUpdate();
      },
      /**
       * Check if the facet are selected.
       *
       * @param {String} facetId - The id of facet.
       * @param {String} value - The value of facet.
       *
       * @returns {Boolean} The selection status of facet.
       */
      _isSelectedFacet(facetId, value) {
        return (this.selectedFacets[facetId] || []).includes(value);
      },
      /**
       * Get the active single facet (used for single facet modal).
       *
       * @returns {Object} facet - The facet data (from search).
       * @returns {String} facet.id - The id of facet.
       * @returns {Object[]} facet.values - The facet values.
       * @returns {String} facet.values[].value - The value of facet.
       * @returns {Number} facet.values[].frequence - The amout of elements with this facet value
       */
      _getActiveFacet() {
        return this.facets.find(facet => facet.id === this.activeFacetId) || {};
      },
      /**
       * Get the amout of selected values for specific facet.
       *
       * @param {String} facetId - The id of facet.
       *
       * @returns {Number} The amount of selected values, 0 or greater.
       */
      _selectedAmount(facetId) {
        return (this.selectedFacets[facetId] || []).length;
      }
    },
    components: {
      "modal-confirm": ModalConfirm,
      "easyscreen-checkbox": EasyscreenCheckbox,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "scrollable": Scrollable,
      "search-single-facet": SearchSingleFacet
    }
  };
</script>
