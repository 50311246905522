var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal-fullscreen',{ref:"modal",class:[
      'easyscreen-search-light',
      { 'easyscreen-search-light_without-popular-searches': !_vm.$easyscreenConfig.get('enable.popularSearches') || !_vm.popularSearches },
      _vm.orientation
    ],style:({ '--easyscreen-search-light-special-color': _vm.specialColor }),attrs:{"hide-empty-container":true,"auto-close":_vm.autoClose,"position":_vm.position},on:{"before-open":(event) => _vm.$emit('before-open', event),"opened":(event) => _vm.$emit('opened', event),"before-close":(event) => _vm.$emit('before-close', event),"closed":(event) => _vm.$emit('closed', event)}},[_c('template',{slot:"content"},[_vm._t("header"),_c('div',{staticClass:"easyscreen-search-light--content"},[(_vm.withCloseControls && _vm.mainScreenClose)?_c('easyscreen-circle-button',{staticClass:"easyscreen-search-light--close-button",attrs:{"icon":"fal fa-times","color":"white","size":"medium"},nativeOn:{"click":function($event){return _vm.hide.apply(null, arguments)}}}):_vm._e(),(_vm.$easyscreenConfig.get('enable.popularSearches') && _vm.popularSearches)?_c('div',{class:[
            'easyscreen-search-light--popular-searches',
            { 'easyscreen-search-light--popular-searches_suggestions-found': _vm.popularSearchesFound }
          ]},[_c('popular-searches-list-with-covers',{ref:"popularSearches",on:{"select":(tag) => _vm.findBy(tag.title),"ready":() => {
              _vm.popularSearchesFound = true;
              _vm._updateSpecialColor(_vm.$refs.popularSearches.firstCoverDominantColor);
            }}})],1):_vm._e(),_c('search-form',{ref:"searchForm",staticClass:"easyscreen-search-light--search-controls",attrs:{"default-sorting":_vm.defaultSorting,"with-sorting":false,"with-search-suggestions":!_vm.searchInProgress,"popular-searches":"false","design":"light"},on:{"find":(query) => _vm.findBy(query)}})],1),_c('search-results-light',{ref:"searchResultsModal",class:[
          'easyscreen-search-light--search-results',
          { 'easyscreen-search-light--search-results_active': _vm.$refs.searchResultsModal && _vm.$refs.searchResultsModal.isShown }
        ],attrs:{"position":_vm.position,"store":_vm.store,"available-only-button":_vm.availableOnlyButton,"shelf-only-button":_vm.shelfOnlyButton,"popular-searches":_vm.popularSearches,"defaultSpecialColor":_vm.specialColor,"with-close-controls":_vm.withCloseControls},on:{"opened":() => _vm.$emit('modal-opened', _vm.$refs.searchResultsModal.hide, 'searchResults'),"closed":() => _vm.$emit('closed-inner-modal', 'searchResults'),"modal-opened":(closeModal, type) => _vm.$emit('modal-opened', closeModal, type),"closed-inner-modal":(type) => _vm.$emit('closed-inner-modal', type),"before-close":() => {
          _vm.$emit('before-search-results-closed');

          if (_vm.$refs.searchForm) {
            _vm.$refs.searchForm.clearQuery();
          }
        }}}),_c('loader',{ref:"searchLoader",attrs:{"position":_vm.position,"design":"light"}})],2)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }