<template>
  <modal-confirm
    ref="modal"
    :class="['search-facets-modal', 'search-single-facet-modal', orientation]"
    :position="position"
    :design="design"
    :cancel-options="{
      color: 'danger'
    }"

    @before-open="(event) => $emit('before-open', event)"
    @opened="(event) => {
      $emit('opened', event);
      $refs.swipeExample && $refs.swipeExample.show();
    }"
    @before-close="(event) => {
      $emit('before-close', event);
      $refs.swipeExample && $refs.swipeExample.hide();
    }"
    @closed="(event) => $emit('closed', event)"
    @ok="(event) => { $emit('selected', this.selected); }"
  >
    <template slot="header">
      <div class="search-single-facet-modal--header">
        <span>
          <span>{{ name }} </span>
          <span v-if="_selectedAmount() !== 0">({{ _selectedAmount() }})</span>
        </span>
        <easyscreen-button
          v-if="_selectedAmount() !== 0"
          class="search-single-facet--clear-button"
          @click.native="_clearSelected"
        >
          {{ _l10n("Clear all facets") }}
        </easyscreen-button>
      </div>
    </template>
    <template slot="content">
      <div class="search-single-facet">
        <swipe-example ref="swipeExample" class="search-single-facet--swipe-example" />
        <scrollable :key="design + orientation" :max-height="design === 'light' && _isLandscape() ? 725 : 820">
          <div class="search-facets-list">
            <div class="search-facets-list--preview">
              <div
                v-for="value in (facet || [])"
                :key="value.value"
                class="search-facets-list--preview-element"
                @click="(event) => {
                  /* Skip the click event by label (checkbox input). */
                  if (!event.defaultPrevented) {
                    _toggleFacet(value.value);
                  }
                }"
              >
                <easyscreen-checkbox
                  class="search-facets-list--preview-checkbox"
                  :design="design"
                  :checked="_isSelectedFacet(value.value)"
                />
                <span class="search-facets-list--preview-element_text">{{ value.name }}</span>
                <span v-if="value.frequence" class="search-facets-list--preview-element_frequence">
                  ({{ value.frequence }})
                </span>
              </div>
            </div>
          </div>
        </scrollable>
      </div>
    </template>
  </modal-confirm>
</template>

<style src="../core/transition.less" lang="less"></style>
<style src="./search-facets.less" lang="less"></style>

<script>
  import ModalConfirm from "../core/modal/confirm.vue";
  import EasyscreenCheckbox from "../core/input/checkbox.vue";
  import EasyscreenButton from "../core/button/button.vue";
  import Scrollable from "../core/scrollable/scrollable.vue";
  import SwipeExample from "../core/swipe-example/swipe-example.vue";

  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../core/mixins/orientation.js";

  export default {
    name: "search-single-facet",
    mixins: [
      orientationMixin
    ],
    props: {
      /* Human readable name of facet. */
      name: String,
      /* The facet values. */
      facet: Array,
      /* The selected by default facets. */
      selectedDefault: Array,
      position: String,
      /* The global reskin for materials list. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    data() {
      return {
        swipeExample: true,
        selected: this.selectedDefault.slice(0) || []
      };
    },
    methods: {
      /**
       * Show the current modal.
       *
       * @param {Function} [callback] - The show callback, will be called when modal is opened.
       */
      show(callback) {
        return this.$refs.modal.show({ callback });
      },
      /**
       * Hide the current modal.
       *
       * @param {Function} [callback] - The hide callback, will be called when modal is closed.
       */
      hide(callback) {
        return this.$refs.modal.hide(callback);
      },
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Select or unselect facet value depends on current state.
       *
       * @param {String} value - The value of facet.
       */
      _toggleFacet(value) {
        if (this.selected.includes(value)) {
          this.selected = this.selected.filter(facet => facet !== value);
        } else {
          this.selected.push(value);
        }
      },
      /**
       * Check if the facet value are selected.
       *
       * @param {String} value - The value of facet.
       *
       * @returns {Boolean} The selection status of facet value.
       */
      _isSelectedFacet(value) {
        return this.selected.includes(value);
      },
      /**
       * Get the amout of selected values.
       *
       * @returns {Number} The amount of selected values, 0 or greater.
       */
      _selectedAmount() {
        return (this.selected || []).length;
      },
      /**
       * Unselect all values.
       * This method will call the vue $forceUpdate.
       */
      _clearSelected() {
        this.selected = [];
        this.$forceUpdate();
      }
    },
    components: {
      "modal-confirm": ModalConfirm,
      "easyscreen-checkbox": EasyscreenCheckbox,
      "easyscreen-button": EasyscreenButton,
      "scrollable": Scrollable,
      "swipe-example": SwipeExample
    }
  };
</script>
