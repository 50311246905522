<template>
  <div
    class="node-list-carousel-only-image-slide"
    :style="{
      backgroundImage: backgroundImage ? `url('${ backgroundImage }')` : null
    }"
  >
    <div
      v-if="qrCode"
      class="node-list-carousel-only-image-slide--qr-code"
      :style="{ backgroundImage: `url('${ $easyscreenRequest.lmsConnector.wrapCover(qrCode) }')` }"
    ></div>
  </div>
</template>

<style src="./node-list-carousel-only-image-slide.less" lang="less"></style>

<script>

  export default {
    name: "node-list-carousel-only-image-slide",
    props: {
      date: String,
      dateTo: String,
      location: String,
      price: [String, Number],
      bgImage: String,
      image: String,
      esTeaser: String,
      teaser: String,
      text: String,
      lead: String,
      qrCode: String
    },
    computed: {
      backgroundImage: {
        get() {
          return this.bgImage || this.image;
        }
      }
    }
  };
</script>
