<template>
  <div :class="['inspiration-scanner', `inspiration-scanner_design-${ design }`]">
    <div
      class="inspiration-scanner--overlay"
      :style="{ backgroundColor: design === 'light' ? specialColor : undefined }"
    ></div>
    <h1 class="inspiration-scanner--title">
      {{ title || _l10n("Scan an item and get inspired") }}
    </h1>
    <div class="inspiration-scanner--logo"></div>

    <modal-alert ref="alert" />
    <inspiration-scanner-carousel
      ref="inspirationCarousel"
      :design="design"
      :position="position"
      :with-navigation-controls="withNavigationControls"
      :title="title || recommendationsTitle"
      :elements="useScanner ? recommendations : promotedMaterials"
      :layout-columns="inspirationCarouselLayout.columns"
      :layout-rows="inspirationCarouselLayout.rows"
      :auto-close="false"
      :forced-special-color="forcedSpecialColor"
      @selected="(element) => _openMaterialList(element.id)"
      @opened="() => $emit('modal-opened', $refs.inspirationCarousel.hide, 'inspirationScannerCarousel')"
      @closed="() => $emit('closed-inner-modal', 'inspirationScannerCarousel')"
    />

    <materials-list
      v-if="selectedMaterial"
      ref="materialsList"
      :use-cache="false"
      :use-suggested-lists="true"
      :design="design"
      :position="position"
      :default-materials="recommendationsIds"
      :default-selected="selectedMaterial"
      :withNavigationControls="withNavigationControls"
      @opened="() => $emit('modal-opened', () => {
        $refs.materialsList && $refs.materialsList.hide();
      }, 'inspirationScannerMaterialsList')"
      @closed="() => $emit('closed-inner-modal', 'inspirationScannerMaterialsList')"
      @modal-opened="(closeModal, type) => $emit('modal-opened', closeModal, 'inspirationScannerMaterialsList' + type)"
      @closed-inner-modal="(type) => $emit('closed-inner-modal', 'inspirationScannerMaterialsList' + type)"
      @select-tag="tag => $emit('select-tag', tag)"
    />
    <loader ref="loader" :design="design" :position="position" />
  </div>
</template>

<style src="./inspiration-scanner.less" lang="less"></style>

<script>
  import { get } from "lodash";
  import * as d3 from "d3-timer";
  import ColorMixer from "color-mixer";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../core/mixins/orientation.js";
  import resizeMixin from "../core/mixins/resize.js";

  import ModalAlert from "../core/modal/alert.vue";
  import Loader from "../core/loader/loader.vue";
  import MaterialsList from "../materials-list/materials-list.vue";
  import InspirationScannerCarousel from "./inspiration-scanner-carousel.vue";

  import * as querystring from "querystring";
  const urlOptions = querystring.parse(window.location.href.split("#")[1]);

  let defaultHSLColor = [0, 100, 100];

  if ("inspirationScannerBackgroundChange" in urlOptions) {
    defaultHSLColor = (urlOptions.inspirationScannerBackgroundColor || "")
      .split(",")
      .map(number => parseInt(number, 10))
      .filter(number => number != null && !isNaN(number));

    if (defaultHSLColor.length !== 3) {
      defaultHSLColor = [0, 60, 60];
    }
  }

  export default {
    name: "inspiration-scanner",
    mixins: [orientationMixin, resizeMixin],
    props: {
      /* Title of the front screen. */
      title: String,
      /* Flag for find the materials with covers only. */
      withCoversOnly: {
        type: Boolean,
        default: false
      },
      /* Flag for find the available only materials. */
      availableOnly: {
        type: Boolean,
        default: false
      },
      /* Used for prioritize the material holdings from selected department. */
      departmentId: String,
      /* Used for prioritize the material holdings from selected branch. */
      branchId: String,
      design: {
        type: String,
        default: "classic",
        /*default: "light",*/
        validator: _design => ["classic", "light"].includes(_design)
      },
      // Override the dynamic special color with the defined value.
      forcedSpecialColor: String,
      defaultSpecialColor: {
        type: Object,
        default: () => ({ hsl: defaultHSLColor })
      },
      position: String,
      withNavigationControls: {
        type: Boolean,
        default: true
      },
      useScanner: {
        type: Boolean,
        default: true
      },
      promotedMaterials: {
        type: Array,
        default: () => ([])
      }
    },
    computed: {
      /**
       * Getter for list of recommended ids.
       *
       * @returns {String[]}
       */
      recommendationsIds() {
        if (!this.useScanner)
          return this.promotedMaterials.map(recommendation => recommendation.id);

        return this.recommendations.map(recommendation => recommendation.id);
      },
      /**
       * Getter for carousel layout based on orientation.
       *
       * @returns {Object} layout
       * @returns {Object} layout.columns
       * @returns {Object} layout.rows
       */
      inspirationCarouselLayout() {
        return {
          columns: this._isLandscape() && this.innerScreenWidth > 1024 ? 5 : 3,
          rows: this._isLandscape() ? 1 : 2
        };
      },
      specialColor() {
        if (this.forcedSpecialColor)
          return this.forcedSpecialColor;

        if (!this.customSpecialColor)
          return;

        return new ColorMixer.Color(this.customSpecialColor).hex();
      }
    },
    data() {
      return {
        scannedMaterialId: null,
        scannedMaterialFaust: null,
        recommendationsTitle: null,
        recommendations: [],
        selectedMaterial: null,
        customSpecialColor: this.defaultSpecialColor
      };
    },
    methods: {
      hide(){
        if (this.$refs.inspirationCarousel)
          this.$refs.inspirationCarousel.hide();

        if (this.$refs.materialsList)
          this.$refs.materialsList.hide();
      },
      show(){
        return this.$refs.inspirationCarousel.show();
      },
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Open materials list with the selected material (by faust number).
       * @async
       *
       * @param {String} elementId - Material faust number.
       */
      async _openMaterialList(elementId) {
        this.selectedMaterial = elementId;
        await this.$nextTick();

        if (!this.$refs.materialsList) {
          this.selectedMaterial = null;
          return;
        }

        return this.$refs.materialsList.show();
      },
      /**
       * Get the recommendations for materil.
       * @async
       *
       * @param {Object} materialDetail
       * @param {String} materialDetail.faustNumber - Material faust number.
       * @param {String} materialDetail.title - Material title.
       * @param {Object} materialDetail.meta
       * @param {String} materialDetail.meta.genre - Material genre.
       * @param {Object[]} materialDetail.holdings - Material holdings.
       * @param {String} materialDetail.holdings[].branchId
       * @param {String} materialDetail.holdings[].departmentId
       */
      async _getRecommendations(materialDetail) {
        try {
          const recommendationsAmount = this.inspirationCarouselLayout.columns * 10;
          const recommendations = await this.$easyscreenRequest.lmsConnector.recommend({
            faustNumber: materialDetail.faustNumber,
            step: this.withCoversOnly || this.availableOnly ? recommendationsAmount * 2 : recommendationsAmount
          });
          const recommendedFaustNumbers = get(recommendations, "items", []).map(recommendation => {
            return get(recommendation, "hasPart[0].pid");
          }).filter(Boolean);

          if (recommendedFaustNumbers.length === 0) {
            throw new Error("No recommendations found");
          }

          const searchResult = await this.$easyscreenRequest.lmsConnector.search({
            include: recommendedFaustNumbers,
            withHoldings: true,
            withCoversOnly: this.withCoversOnly,
            availableOnly: this.availableOnly,
            departmentId: this.departmentId,
            branchId: this.branchId,
            step: recommendationsAmount
          });

          if (searchResult.hitCount === 0) {
            throw new Error("No recommendations found");
          }

          const materialTitle = [
            get(materialDetail, "title"),
            get(materialDetail, "meta.genre")
          ].filter(Boolean).join(" : ");

          /*
           * Convert regular material detail to inspiration scanner carousel element.
           * The base format is same;
           * added:
           * - `library` - library name;
           * - `placement` - the material placement without the library as first column.
           */
          this.recommendations = searchResult.objects.map(materialDetail => {
            let holding = materialDetail.holdings[0];
            if (this.departmentId || this.branchId) {
              const libraryBasedHolding = materialDetail.holdings.find(holding => {
                const isSameBranchId = !this.branchId || !holding.branchId || holding.branchId === this.branchId;
                const isSameDepartmentId = !this.departmentId || !holding.departmentId || holding.departmentId === this.departmentId;

                return isSameBranchId && isSameDepartmentId;
              });

              holding = libraryBasedHolding || holding;
            }

            const placement = holding ? holding.placement : "";
            const placementDelimeterIndex =  placement.indexOf(" > ");

            return {
              ...materialDetail,
              library: placement.slice(0, placementDelimeterIndex).trim(),
              placement: placement.slice(placementDelimeterIndex + 3, placement.length).trim()
            };
          });
          this.recommendationsTitle = `${ l10n("Items which is related to ").trim() } "${ materialTitle }"`;

          if (this.$refs.loader)
            this.$refs.loader.hide();

          if (this.$refs.inspirationCarousel)
            this.$refs.inspirationCarousel.show();
        } catch (error) {
          console.error("Can't fetch recommendations.", error);

          this.$refs.loader.hide();
          this._resetInspirationScanner();

          this.$refs.alert.show({
            title: l10n("No materials found"),
            message: l10n("Suggestions not found for scanned material, please try another one.")
          });
        }
      },
      /**
       * Reset recommendations and opened material views (revert component to initial state).
       */
      _resetInspirationScanner() {
        this.scannedMaterialId = null;
        this.scannedMaterialFaust = null;
        this.recommendationsLabel = null;
        this.recommendations = [];

        if (this.$refs.loader)
          this.$refs.loader.hide();

        if (this.$refs.inspirationCarousel)
          this.$refs.inspirationCarousel.hide();

        if (this.$refs.materialsList)
          this.$refs.materialsList.hide();
      },
      /**
       * Handler for rfid scanner inventory update event (material added or removed from scanner).
       * @async
       *
       * @param {Object[]} inventory - Rfid scanner inventory.
       */
      async _onScannerInventoryUpdate(inventory) {
        if (Object.keys(inventory).length === 0) {
          return this._resetInspirationScanner();
        }

        const lastAddedMaterialId = Object.keys(inventory).sort((materialIdA, materialIdB) => {
          return inventory[materialIdB].created - inventory[materialIdA].created;
        })[0];

        /* The last added materail did not changed. */
        if (this.scannedMaterialId === lastAddedMaterialId) {
          return;
        }

        if (this.$refs.loader)
          this.$refs.loader.show();

        this.scannedMaterialId = lastAddedMaterialId;
        const lastAddedMaterial = inventory[lastAddedMaterialId];

        await lastAddedMaterial.loadingPromise;
        /* The last added material has the same faust number; the update is not required. */
        if (this.scannedMaterialFaust === get(lastAddedMaterial, "detail.faustNumber")) {
          return;
        }

        return this._getRecommendations(lastAddedMaterial.detail);
      },
      _initBackgroundColorRotation() {
        this._initBackgroundColorRotationInterval = d3.interval(() => {
          let hue = (this.customSpecialColor.hsl[0] + (parseInt(urlOptions.inspirationScannerBackgroundStep, 10) || 2)) % 359;

          this.customSpecialColor = {
            hsl: [hue, this.customSpecialColor.hsl[1], this.customSpecialColor.hsl[2]]
          };
        }, parseInt(urlOptions.inspirationScannerBackgroundChange, 10) || 500);
      },
      _destroyBackgroundColorRotation() {
        if (this._initBackgroundColorRotationInterval) {
          this._initBackgroundColorRotationInterval.stop();
          this._initBackgroundColorRotationInterval = null;
        }
      }
    },
    mounted() {
      if (!this.useScanner)
        return this.$refs.inspirationCarousel.show();


      this.$friendlyFrankScanner.on("inventory-updated", this._onScannerInventoryUpdate);
      if (!this.$friendlyFrankScanner.isEmpty()) {
        this._onScannerInventoryUpdate(this.$friendlyFrankScanner.inventory);
      }

      if (this.design === "light") {
        if ("inspirationScannerBackgroundChange" in urlOptions) {
          this._initBackgroundColorRotation();
        } else {
          this.customSpecialColor = null;
        }
      }
    },
    beforeDestroy() {
      if (!this.useScanner)
        return;

      this.$friendlyFrankScanner.off("inventory-updated", this._onScannerInventoryUpdate);
      this._destroyBackgroundColorRotation();
    },
    components: {
      "modal-alert": ModalAlert,
      "loader": Loader,
      "materials-list": MaterialsList,
      "inspiration-scanner-carousel": InspirationScannerCarousel
    }
  };
</script>
