<template>
  <div>
    <modal-fullscreen
      ref="modal"

      :color-scheme="design === 'classic' ? 'easyscreen-menu' : undefined"
      :position="position"

      @before-open="(event) => $emit('before-open', event)"
      @opened="(event) => $emit('opened', event)"
      @before-close="(event) => $emit('before-close', event)"
      @closed="(event) => { $emit('closed', event); }"

      :class="[
        'inspiration-scanner-carousel',
        `inspiration-scanner-carousel_design-${ design }`,
        orientation
      ]"
      :style="{ '--easyscreen-inspiration-scanner-carousel-special-color': design === 'light' ? specialColor : undefined }"
      :hide-empty-container="true"
      :auto-close="autoClose"
      header-height="150px"
    >
      <template v-if="title" slot="header-center">
        <div class="inspiration-scanner-carousel--title">
          {{ title }}
        </div>
      </template>
      <template slot="header-right">
        <easyscreen-circle-button
          v-if="withNavigationControls"
          class="easyscreen-search-results--close-button"
          icon="/images/es-menu/close_icon.png"
          icon-type="image"

          @click.native="hide"
        />
        <span></span>
      </template>
      <template slot="content">
        <div class="inspiration-scanner-carousel--content">
          <easyscreen-carousel
            ref="carousel"
            class="inspiration-scanner-carousel--carousel"
            :optimization="false"
            :layout-columns="layoutColumns"
            :layout-rows="layoutRows"
            @before-position-change="(_, followingSlideIndex) => {
              activeSlideIndex = followingSlideIndex;
              _updateSpecialColor();
            }"
          >
            <div
              v-for="(element, elementIndex) in elements"
              :key="element.id"
              class="inspiration-scanner-carousel--element"
              @click="$emit('selected', element, elementIndex)"
            >
              <div class="inspiration-scanner-carousel--element-cover">
                <img
                  class="inspiration-scanner-carousel--element-image"
                  draggable="false"
                  :src="$easyscreenRequest.lmsConnector.wrapCover(element.cover)"
                  crossOrigin="annonimus"
                >
                <div class="inspiration-scanner-carousel--element-type">
                  {{ element.type }}
                </div>
              </div>
              <div class="inspiration-scanner-carousel--element-title">
                {{ element.title }}
              </div>
              <div class="inspiration-scanner-carousel--element-library">
                {{ element.library }}
              </div>
              <div class="inspiration-scanner-carousel--element-placement">
                {{ element.placement }}
              </div>
            </div>
          </easyscreen-carousel>
          <div class="inspiration-scanner-carousel--navigation">
            <div
              v-for="slideNumber in slidesAmount"
              :key="`slide-number-${ slideNumber }`"
              :class="[
                'inspiration-scanner-carousel--navigation-point',
                { 'inspiration-scanner-carousel--navigation-point_active': slideNumber - 1 === activeSlideIndex }
              ]"
              @click="() => $refs.carousel.selectByIndex(slideNumber - 1)"
            ></div>
          </div>
        </div>
      </template>
    </modal-fullscreen>
  </div>
</template>

<style src="./inspiration-scanner-carousel.less" lang="less"></style>

<script>
  import orientationMixin from "../core/mixins/orientation.js";
  import getDominantImageColor from "@/lib/utils/get-dominant-image-color.js";

  import ModalFullscreen from "../core/modal/fullscreen.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import EasyscreenCarousel from "../core/carousel/carousel.vue";

  export default {
    name: "inspiration-scanner-carousel",
    mixins: [orientationMixin],
    props: {
      title: String,
      elements: Array,
      layoutColumns: {
        type: Number,
        default: 3
      },
      layoutRows: {
        type: Number,
        default: 1
      },
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      },
      // Override the dynamic special color with the defined value.
      forcedSpecialColor: String,
      defaultSpecialColor: {
        type: String,
        default: "#f7f7f7"
      },
      position: String,
      withNavigationControls: {
        type: Boolean,
        default: true
      },
      /* Close the popup when standby is started. */
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      /* Proxy for modal isShown variable. */
      isShown: {
        cache: false,
        get() {
          return this.$refs.modal && this.$refs.modal.isShown;
        }
      },
      slidesAmount() {
        const elementsPerSlide = this.layoutRows * this.layoutColumns;
        return Math.ceil(this.elements.length / elementsPerSlide);
      },
      specialColor() {
        if (this.forcedSpecialColor)
          return this.forcedSpecialColor;

        return this.customSpecialColor || this.defaultSpecialColor;
      }
    },
    data() {
      return {
        customSpecialColor: null,
        activeSlideIndex: 0
      };
    },
    methods: {
      /**
       * Show the current modal. Async when callback is not provided.
       * @async
       *
       * @param {Function} [callback] - The show callback, will be called when modal is opened.
       */
      async show(callback) {
        await this.$refs.modal.show();

        this._updateSpecialColor();
        if (callback) {
          callback();
        }

        return;
      },
      /**
       * Hide the current modal. Async when callback is not provided.
       * @async
       *
       * @param {Function} [callback] - The hide callback, will be called when modal is closed.
       */
      hide(callback) {
        return this.$refs.modal.hide(callback);
      },
      _isDefaultCover(url) {
        return (url || "").includes("defaultCover.jpg");
      },
      async _updateSpecialColor() {
        const images = this.$refs.carousel.$el.querySelectorAll("img");
        const elementsPerSlide = this.layoutRows * this.layoutColumns;
        const targetImageIndexes = new Array(elementsPerSlide).fill(null).map((_, index) => {
          return this.activeSlideIndex * elementsPerSlide + index;
        });

        for (let index of targetImageIndexes) {
          const image = images[index];
          if (image && !this._isDefaultCover(image.getAttribute("src"))) {
            const specialColor = await getDominantImageColor(images[index]);
            if (specialColor) {
              this.customSpecialColor = specialColor;
              break;
            }
          }
        }
      }
    },
    components: {
      "modal-fullscreen": ModalFullscreen,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "easyscreen-carousel": EasyscreenCarousel
    }
  };
</script>
