<template>
  <div
    :class="[
      'node-list-carousel',
      `node-list-carousel_scale-${ $easyscreenScale }`,
      `node-list-carousel_template-${ slideTemplateType }`,
      orientation
    ]"
    :style="{
      '--special-color': customSpecialColor
    }"
  >
    <btj-header
      v-if="$easyscreenSkin.isBtj"
      class="node-list-carousel--btj-header"
      size="big"
    />

    <krsbib-header
      v-if="template === 'krsbib-twin-items'"
      :title="slideSettings.overrideTitle"
      :class="[
        'node-list-carousel--krsbib-header',
        {
          'node-list-carousel--krsbib-header_events': isEvent,
          'node-list-carousel--krsbib-header_news': !isEvent
        }
      ]"
    />

    <h2 class="node-list-carousel--title">
      {{ title }}
    </h2>
    <easyscreen-carousel
      ref="carousel"
      class="node-list-carousel--carousel"
      type="loop"
      :optimization="false"
      :transition="carouselTransition"
      :layout-columns="carouselLayout.columns"
      :layout-rows="carouselLayout.rows"
      @position-changed="(_activeSlideIndex) => activeSlideIndex = _activeSlideIndex"
    >
      <div
        v-for="(element, elementIndex) in items"
        :key="element.nid"
        class="node-list-carousel--element"
        @click="() => _expandElement(element, elementIndex)"
      >
        <slide
          ref="carouselSlides"
          :is="slideTemplate"

          :totalElements="Math.ceil(items.length / (carouselLayout.columns * carouselLayout.rows))"
          :elementIndex="elementIndex"
          :slideRows="carouselLayout.rows"
          :elementRow="elementIndex % carouselLayout.rows"
          v-bind="element"
          :withSlideDelimiter="elementIndex % carouselLayout.rows < carouselLayout.rows - 1"
        />

        <easyscreen-carousel-ribbon
          v-if="element.status && element.status.title"
          :title="element.status.title"
          :background="element.status.color"
          :fontSize="element.status.fontSize"
          :aligment="element.status.aligment"
        />
      </div>
    </easyscreen-carousel>

    <krsbib-footer
      v-if="template === 'krsbib-twin-items'"
      :class="[
        'node-list-carousel--krsbib-footer',
        {
          'node-list-carousel--krsbib-footer_events': isEvent,
          'node-list-carousel--krsbib-footer_news': !isEvent
        }
      ]"
    />

    <modal-blank
      v-if="isEvent && hasDetails"
      ref="modal"
      :click-to-close="true"
    >
      <div>
        <node-list-info
          v-if="activeElement"
          :type="activeElement.type"
          :date="activeElement.date"
          :date-to="activeElement.dateTo"
          :location="activeElement.location"
          :price="activeElement.price"
          :image="activeElement.image"
          :title="activeElement.title"
          :es-teaser="activeElement.esTeaser"
          :teaser="activeElement.teaser"
          :text="activeElement.text"
          :lead="activeElement.lead"
        >
          <easyscreen-circle-button
            class="node-list-carousel--close-button node-list-carousel--close-button_top-right"
            icon="esi esi-times"

            @click.native="$refs.modal.hide"
          />

          <easyscreen-circle-button
            class="node-list-carousel--close-button node-list-carousel--close-button_bottom-right"
            icon="esi esi-times"

            @click.native="$refs.modal.hide"
          />
        </node-list-info>
      </div>
    </modal-blank>
  </div>
</template>

<style src="./node-list-carousel.less" lang="less"></style>

<script>
  import lodash from "lodash";
  import * as d3 from "d3-timer";
  import asyncTimeout from "@/lib/utils/async-timeout.js";
  import orientationMixin from "../core/mixins/orientation.js";

  import ModalBlank from "../core/modal/blank.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import EasyscreenCarousel from "../core/carousel/carousel.vue";
  import EasyscreenCarouselRibbon from "../core/carousel/carousel-ribbon.vue";
  import BtjHeader from "../core/btj-header/btj-header.vue";
  import KrsbibHeader from "../core/krsbib-header/krsbib-header.vue";
  import KrsbibFooter from "../core/krsbib-footer/krsbib-footer.vue";
  import NodeListInfo from "./node-list-info.vue";
  import NodeListCarouselDefaultSlide from "./node-list-carousel-slide/node-list-carousel-default-slide.vue";
  import NodeListCarouselOnlyImageSlide from "./node-list-carousel-slide/node-list-carousel-only-image-slide.vue";
  import NodeListCarouselHorsholmKrsbibSlide from "./node-list-carousel-slide/node-list-carousel-horsholm-krsbib-slide.vue";
  import NodeListCarouselKrsbibSlide from "./node-list-carousel-slide/node-list-carousel-krsbib-slide.vue";
  import NodeListCarouselNaesbibSlide from "./node-list-carousel-slide/node-list-carousel-naesbib-slide.vue";
  import NodeListCarouselBtjSlide from "./node-list-carousel-slide/node-list-carousel-btj-slide.vue";
  import NodeListCarouselBronbibSlide from "./node-list-carousel-slide/node-list-carousel-bronbib-slide.vue";
  import NodeListCarouselVestbibSlide from "./node-list-carousel-slide/node-list-carousel-vestbib-slide.vue";
  import NodeListCarouselHerlevSlide from "./node-list-carousel-slide/node-list-carousel-herlev-slide.vue";
  import NodeListCarouselSlagbibBookingSlide from "./node-list-carousel-slide/node-list-carousel-slagbib-booking-slide.vue";
  import NodeListCarouselSingleDetailSlide from "./node-list-carousel-slide/node-list-carousel-single-detail-slide.vue";
  import NodeListCarouselColorImageSlide from "./node-list-carousel-slide/node-list-carousel-color-image-slide.vue";

  export default {
    name: "node-list-carousel",
    mixins: [orientationMixin],
    props: {
      /* Widget title. */
      title: String,
      /* The list of events. */
      items: Array,
      template: {
        type: String,
        default: "default",
        validator: _template => [
          "default",
          "slagbib-booking",
          "single-detail",
          "color-image",
          "krsbib-twin-items",
          "only-image"
        ].includes(_template)
      },
      /* The layout of view. */
      layout: {
        type: [String, Array],
        default: "six-elements",
        validator: _layout => lodash.castArray(_layout).reduce((options, option) => {
          return options.concat(option.split(" "));
        }, []).filter(Boolean).every(option => {
          return [
            "six-elements",
            "eight-elements",
            "ten-elements",
            "cinema",
            "for-game",
            "welcome-screen-modifer",
            "events",
            "news"
          ].includes(option);
        })
      },
      /* The duration of auto scroll till end of list. */
      animationDuration: {
        type: Number,
        default: 5
      },
      /* Flag for enable the autoamimation on standby. */
      autoAnimation: {
        type: Boolean,
        default: false
      },
      slideSettings: Object
    },
    computed: {
      customSpecialColor() {
        if (lodash.get(this.slideSettings, "settings.topBarColorEnabled") !== true)
          return null;

        return lodash.get(this.slideSettings, "settings.topBarColor");
      },
      hasDetails() {
        return [
          !this.$easyscreenSkin.isBronbib,
          !this.$easyscreenSkin.isVestbib,
          !this.$easyscreenSkin.isHerlev,
          this.template !== "slagbib-booking",
          this.template !== "single-detail",
          this.template !== "color-image"
        ].every(withoutDetails => withoutDetails === false);
      },
      slideTemplateType() {
        return (this.slideTemplate || "").replace("node-list-carousel-", "").replace("-slide", "");
      },
      slideTemplate() {
        if (this.template === "slagbib-booking") {
          return "node-list-carousel-slagbib-booking-slide";
        } else if (this.template === "single-detail") {
          return "node-list-carousel-single-detail-slide";
        } else if (this.template === "color-image") {
          return "node-list-carousel-color-image-slide";
        } else if (this.template === "krsbib-twin-items") {
          return "node-list-carousel-krsbib-slide";
        } else if (this.$easyscreenSkin.isHorsholm || this.$easyscreenSkin.isKrsbib) {
          return "node-list-carousel-horsholm-krsbib-slide";
        } else if (this.$easyscreenSkin.isNaesbib) {
          return "node-list-carousel-naesbib-slide";
        } else if (this.$easyscreenSkin.isBtj) {
          return "node-list-carousel-btj-slide";
        } else if (this.$easyscreenSkin.isBronbib) {
          return "node-list-carousel-bronbib-slide";
        } else if (this.$easyscreenSkin.isVestbib) {
          return "node-list-carousel-vestbib-slide";
        } else if (this.$easyscreenSkin.isHerlev) {
          return "node-list-carousel-herlev-slide";
        } else if (this.template === "only-image") {
          return "node-list-carousel-only-image-slide";
        }

        return "node-list-carousel-default-slide";
      },
      carouselTransition() {
        if (this.$easyscreenSkin.isHerlev) {
          return "fade";
        }

        return "slide";
      },
      carouselLayout() {
        let layout = {
          columns: 1,
          rows: 1
        };

        if (this.$easyscreenSkin.isHorsholm || this.$easyscreenSkin.isKrsbib || this.$easyscreenSkin.isBtj) {
          if (this.orientation === "portrait-orientation" && !["single-detail", "color-image"].includes(this.template)) {
            layout.rows = 2;
          }
        }

        return layout;
      },
      isEvent() {
        return this.layout && this.layout.includes("events");
      },
      animationDurationMs() {
        return this.animationDuration * 1000;
      }
    },
    watch: {
      items: {
        handler() {
          this._onElementsUpdate();
        },
        deep: true
      }
    },
    data() {
      return {
        activeSlideIndex: 0,
        activeElement: null
      };
    },
    methods: {
      /**
       * Start auto animation with interval `this.animationDurationMs`.
       */
      _startAutoAnimation() {
        this._stopAutoAnimation();

        this._autoAnimationInterval = d3.interval(this.$refs.carousel.nextSlide, this.animationDurationMs);
      },
      /**
       * Stop auto animation.
       */
      _stopAutoAnimation() {
        if (this._autoAnimationInterval) {
          this._autoAnimationInterval.stop();
          this._autoAnimationInterval = null;
        }
      },
      /**
       * Open element info.
       *
       * @param {Object} element
       * @param {String} element.type - "event" or "news".
       * @param {String} element.date - From date of event.
       * @param {String} element.dateTo - End date of event.
       * @param {String} element.location - Event location.
       * @param {String} [element.price] - Event price
       * @param {String} element.image - Preview image for event.
       * @param {String} element.title - Event\News title.
       * @param {String} element.esTeaser - Event\News description (not used).
       * @param {String} element.teaser - Event\News description (not used).
       * @param {String} element.text - Event\News description (not used).
       * @param {String} element.lead - Event\News description.
       */
      _expandElement(element, index) {
        if (!this.$refs.modal) {
          return;
        }

        this.$easyscreenStatistic.openNodeList({
          nid: this.items[index].nid,
          title: this.items[index].title
        });

        if (this.layout.includes("welcome-screen-modifer")) {
          this.$emit("expand-element", this.items[index]);
        } else {
          this.activeElement = element;
          this.$refs.modal.show();
        }
      },
      /**
       * Set max found title height for elements on carousel with type "single-detail".
       */
      async _adjustSingleDetailTitle() {
        if (this.template === "single-detail") {
          const maxTitleWidth = Math.max.apply(null, (this.$refs.carouselSlides || []).map(slide => {
            return slide.getDefaultTitleHeight();
          }));

          (this.$refs.carouselSlides || []).forEach(slide => {
            slide.setTitleHeight(maxTitleWidth);
          });
        }
      },
      /**
       * Handler for elements update event. Updates title height for carousel with type "single-detail".
       */
      _onElementsUpdate() {
        this._adjustSingleDetailTitle();
      },
      /**
       * Handler for orientation change update event. Updates title height for carousel with type "single-detail".
       */
      _onOrientationChange() {
        this._adjustSingleDetailTitle();
      }
    },
    async mounted() {
      this.$on("orientation-changed", this._onOrientationChange);
      /* Wait for styles apply on first load. */
      await asyncTimeout(100);
      this._onElementsUpdate();
    },
    screenStandby() {
      if (this.autoAnimation) {
        this._startAutoAnimation();
      }
    },
    screenActive() {
      this._stopAutoAnimation();
    },
    beforeDestroy() {
      this.$off("orientation-changed", this._onOrientationChange);
    },
    components: {
      "modal-blank": ModalBlank,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "easyscreen-carousel": EasyscreenCarousel,
      "easyscreen-carousel-ribbon": EasyscreenCarouselRibbon,
      "btj-header": BtjHeader,
      "krsbib-header": KrsbibHeader,
      "krsbib-footer": KrsbibFooter,
      "node-list-info": NodeListInfo,
      "node-list-carousel-default-slide": NodeListCarouselDefaultSlide,
      "node-list-carousel-only-image-slide": NodeListCarouselOnlyImageSlide,
      "node-list-carousel-horsholm-krsbib-slide": NodeListCarouselHorsholmKrsbibSlide,
      "node-list-carousel-krsbib-slide": NodeListCarouselKrsbibSlide,
      "node-list-carousel-naesbib-slide": NodeListCarouselNaesbibSlide,
      "node-list-carousel-btj-slide": NodeListCarouselBtjSlide,
      "node-list-carousel-bronbib-slide": NodeListCarouselBronbibSlide,
      "node-list-carousel-vestbib-slide": NodeListCarouselVestbibSlide,
      "node-list-carousel-herlev-slide": NodeListCarouselHerlevSlide,
      "node-list-carousel-slagbib-booking-slide": NodeListCarouselSlagbibBookingSlide,
      "node-list-carousel-single-detail-slide": NodeListCarouselSingleDetailSlide,
      "node-list-carousel-color-image-slide": NodeListCarouselColorImageSlide
    }
  };
</script>
