var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'digital-shelf-light',
    { 'digital-shelf-light_medium-screen': _vm.isMediumScreen },
    _vm.orientation
  ],style:({ '--digital-shelf-light-special-color': _vm.specialColor })},[_c('modal-layout-navigation',{ref:"screenNavigation",attrs:{"title":_vm.title,"withSearch":!_vm.disableExternalFeatures,"searchDisabled":_vm.disableExternalFeatures || _vm.searchShown,"wayfinder-data":_vm.wayfinderData,"disable-navigation":_vm.disableNavigation},on:{"show-search":() => {
      _vm.$refs.screenNavigation.toHome();
      _vm.$refs.search.show();
    }}},[_c('div',{class:[
        'digital-shelf-light--theme-data',
        { 'digital-shelf-light--theme-data_info-hidden': _vm.themeInfoHidden }
      ],style:({ backgroundColor: _vm.themeColor, height: this.themeInfoHeight ? `${ this.themeInfoHeight }px` : null })},[_c('div',{staticClass:"digital-shelf-light--theme-data-wrapper"},[_c('h3',{staticClass:"digital-shelf-light--theme-label"},[_vm._v(" "+_vm._s(_vm.getActiveTag())+" ")]),_c('div',{class:[
          'digital-shelf-light--theme-info',
          { 'digital-shelf-light--theme-info_disabled': !_vm.getHelpText() }
        ]},[_c('div',{staticClass:"digital-shelf-light--more-theme-info",on:{"click":_vm.toggleThemeInfo}},[_c('i',{class:[
                'digital-shelf-light--more-theme-info-icon',
                'fal',
                {
                  'fa-arrow-down': _vm.themeInfoHidden,
                  'fa-times': !_vm.themeInfoHidden
                }
              ]}),_c('span',{staticClass:"digital-shelf-light--more-theme-info-label"},[_vm._v(_vm._s(_vm._l10n("Introduction to the topic")))])]),_c('scrollable',{ref:"themeText",staticClass:"digital-shelf-light--theme-text",style:({ height: _vm.themeContentHeight ? `${ _vm.themeContentHeight }px` : _vm.themeContentHeight }),attrs:{"max-height":_vm.themeContentHeight,"smooth-edge-color":_vm.specialColorWithOverlay}},[(!!_vm._getHelpVideo() && !_vm.themeInfoHidden)?_c('easyscreen-youtube',{staticClass:"digital-shelf-light--theme-video",attrs:{"url":_vm._getHelpVideo(),"muted":false}}):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(_vm.getHelpText())}})],1)],1)])]),_c('div',{staticClass:"digital-shelf-light--content"},[_c('div',{staticClass:"digital-shelf-light--sliders"},[(_vm.$easyscreenConfig.get('enable.accessibility'))?_c('div',[_c('easyscreen-circle-button',{class:[
              'digital-shelf-light--carousel-navigation',
              'digital-shelf-light--carousel-navigation_left',
              { 'digital-shelf-light--carousel-navigation_visible': _vm.leftCarouselNavigationButtonVisible }
            ],attrs:{"icon":"fal fa-chevron-left","color":"outline-custom","size":_vm.isMediumScreen ? 'medium' : 'big',"custom-color":_vm.specialColor,"custom-active-color":_vm.specialColorWithOverlay},nativeOn:{"click":function($event){return _vm.$refs.carousel.previousSlide()}}})],1):_vm._e(),_c('transition',{attrs:{"name":"digital-shelf-light--slider","mode":"out-in"},on:{"after-leave":() => {
          /*_loadDominantCoverColor(selected, carouselPageIndex, 100);*/
        }}},[_c('easyscreen-carousel',{key:`slider-${ _vm.selected }-${ _vm._isLandscape() }`,ref:"carousel",staticClass:"digital-shelf-light--slider",attrs:{"layout-columns":_vm.carouselColumns,"layout-rows":_vm.carouselRows},on:{"before-position-change":(_, followingSlideIndex) => {
              _vm.closeThemeInfo()
              _vm.carouselPageIndex = followingSlideIndex;
              /*_loadDominantCoverColor(selected, carouselPageIndex);*/
            },"dragging-started":_vm.closeThemeInfo}},[_vm._l((_vm.carouselElements),function(element,elementIndex){return [(element.isStub)?_c('div',{key:`stab-${ elementIndex }`}):_c('digital-shelf-cover',{key:element.faustNumber,ref:"covers",refInFor:true,class:['digital-shelf-light--cover', { 'digital-shelf-light--cover_no-padding': _vm.isMediumScreen }],attrs:{"with-cover-background":false,"title":element.title,"author":element.author,"cover":element.cover,"align":"bottom","horizontal-align":"center","width":"75%"},nativeOn:{"click":function($event){{
                  _vm.$refs.screenNavigation.pushBackAction(() => { _vm.$refs.materialsList.hide() });
                  _vm._showMaterialView(element, _vm.selected);
                }}}})]})],2)],1),(_vm.$easyscreenConfig.get('enable.accessibility'))?_c('div',[_c('easyscreen-circle-button',{class:[
              'digital-shelf-light--carousel-navigation',
              'digital-shelf-light--carousel-navigation_right',
              { 'digital-shelf-light--carousel-navigation_visible': _vm.rightCarouselNavigationButtonVisible }
            ],attrs:{"icon":"fal fa-chevron-right","color":"outline-custom","size":_vm.isMediumScreen ? 'medium' : 'big',"custom-color":_vm.specialColor,"custom-active-color":_vm.specialColorWithOverlay},nativeOn:{"click":function($event){return _vm.$refs.carousel.nextSlide()}}})],1):_vm._e()],1),_c('div',{staticClass:"digital-shelf-light--themes-wrapper"},[_c('div',{staticClass:"digital-shelf-light--themes",style:({ '--digital-shelf-light--theme-lines': Math.ceil(_vm.tags.length / _vm.themesPerLine) })},[_vm._l((_vm.tags),function(theme,themeIndex){return [_c('div',{key:`${ theme }-${ themeIndex }`,class:[
                'digital-shelf-light--theme',
                { 'digital-shelf-light--theme_active': themeIndex === _vm.selected }
              ],on:{"click":() => {
                _vm.closeThemeInfo();
                _vm._selectTag(themeIndex);
              }}},[_c('span',{staticClass:"digital-shelf-light--theme-text_default"},[_vm._v(_vm._s(theme))]),_c('span',{staticClass:"digital-shelf-light--theme-text_active"},[_vm._v(_vm._s(theme))])]),((themeIndex + 1) % _vm.themesPerLine === 0 && themeIndex !== 0)?_c('div',{key:`theme-break-${ themeIndex }`,staticClass:"digital-shelf-light--theme-break"}):_vm._e()]})],2)]),_c('div',{staticClass:"digital-shelf-light--dynamic-footer-line",style:({ backgroundColor: _vm.themeColor })},_vm._l((_vm.carouselSlidesAmount),function(number){return _c('div',{key:number,class:[
            'digital-shelf-light--slide-indicator',
            { 'digital-shelf-light--slide-indicator_active': _vm.carouselPageIndex === number - 1 }
          ]})}),0),_c('div',{staticClass:"digital-shelf-light--static-footer-line"})]),(!_vm.disableExternalFeatures)?_c('easyscreen-search-light',{ref:"search",attrs:{"position":"absolute","popular-searches":"multiline","auto-close":true,"available-only-button":true,"with-close-controls":false,"default-special-color":_vm.specialColor},on:{"before-open":() => {
        _vm.$refs.screenNavigation.pushBackAction(() => {
          _vm.$refs.search.hide();
        });
        _vm.searchShown = true;
      },"before-close":function($event){_vm.searchShown = false},"modal-opened":(closeModal, type) => _vm.$refs.screenNavigation.pushBackAction(closeModal, type),"closed-inner-modal":(type) => _vm.$refs.screenNavigation.popBackAction(type)}}):_vm._e(),(_vm.selectedMaterial)?_c('materials-list',{ref:"materialsList",attrs:{"design":"light","force-use-controls":_vm.disableNavigation,"auto-close":true,"use-suggested-lists":_vm.isVideoHostTemplate === false && this.suggestedLists,"default-materials":_vm.selectedMaterialsList,"default-selected":_vm.selectedMaterial},on:{"select-tag":(tag) => {
        _vm.$refs.screenNavigation.toHome();
        _vm._findByTag(tag);
      },"modal-opened":(closeModal, type) => _vm.$refs.screenNavigation.pushBackAction(closeModal, type),"closed-inner-modal":(type) => _vm.$refs.screenNavigation.popBackAction(type)}}):_vm._e()],1),(_vm.$easyscreenConfig.get('enable.unloan'))?_c('patron-profile',{ref:"patronProfile"}):_vm._e(),(_vm.$easyscreenConfig.get('enable.basket'))?_c('easyscreen-materials-cart',{ref:"materialsCart"}):_vm._e(),(_vm.$easyscreenConfig.get('enable.profile'))?_c('materials-scanner',{ref:"materialsScanner"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }