<template>
  <component
    ref="screenNavigation"
    class="standalone-search"
    :is="design === 'light' ? 'modal-layout-navigation' : 'div'"
    :title="title || $easyscreenConfig.get('library.name', $easyscreenConfig.name)"
    :disable-navigation="disableNavigation"
    :wayfinder-data="wayfinderData"
  >
    <component
      ref="search"
      position="absolute"
      popular-searches="multiline"
      :is="design === 'light' ? 'easyscreen-search-light' : 'easyscreen-search'"
      :available-only-button="true"
      :available-only="true"
      :shelf-only-button="!!queryPrefix"
      :shelf-only="!!queryPrefix"
      :auto-close="false"
      :with-close-controls="disableNavigation || false"
      :main-screen-close="false"
      :query-prefix="queryPrefix"
      @before-hot-update="winproxy.setTimeout(_initialize, 500)"
      @modal-opened="(closeModal, type) => $refs.screenNavigation.pushBackAction(closeModal, type)"
      @closed-inner-modal="(type) => $refs.screenNavigation.popBackAction(type)"
    >
      <template slot="header">
        <div class="standalone-search--actions">
          <div

            v-if="$easyscreenConfig.get('enable.profile')"
            class="digital-shelf--shortcut"
            @click="_showProfile"
          >
            <i class="esi esi-user"></i>
          </div>
          <div
            v-if="$easyscreenConfig.get('enable.unloan')"
            class="digital-shelf--shortcut"
            @click="_showUnloan"
          >
            <i class="esi esi-hand-in"></i>
          </div>
          <div
            v-if="withBasket"
            class="digital-shelf--shortcut"
            @click="_showCart"
          >
            <easyscreen-materials-cart-button />
          </div>
        </div>
      </template>
    </component>

    <materials-scanner v-if="$easyscreenConfig.get('enable.unloan')" ref="materialsScanner" />
    <patron-profile v-if="$easyscreenConfig.get('enable.profile')" ref="patronProfile" />
    <easyscreen-materials-cart v-if="withBasket" ref="materialsCart" />
  </component>
</template>

<style src="../digital-shelf/shortcut.less" lang="less"></style>
<style src="./standalone.less" lang="less"></style>
<script>
  import ModalLayoutNavigation from "@/components/core/modal/layout-navigation.vue";

  import EasyscreenSearch from "./search.vue";
  import EasyscreenSearchLight from "./search-light.vue";
  import PatronProfile from "../patron/profile/profile.vue";
  import MaterialsScanner from "../materials-scanner/materials-scanner.vue";
  import EasyscreenMaterialsCart from "../materials-cart/materials-cart.vue";
  import EasyscreenMaterialsCartButton from "../materials-cart/materials-cart-button.vue";

  export default {
    name: "standalone-search",
    props: {
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      },
      /* The title of search results screen, used for shelfOnly feature. */
      title: String,
      /* The prefix of query which used for shelfOnly feature. */
      queryPrefix: String,
      /* The data for open wayfinder from the bottom navigation. */
      wayfinderData: Object,
      disableNavigation: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      withBasket() {
        return this.$easyscreenConfig.get("enable.basket") && this.design !== "light";
      }
    },
    methods: {
      /**
       * Initialize the visual shelf search (show the main search screen).
       */
      async _initialize() {
        this.$refs.search.show();
        if (this.queryPrefix)
          this.$refs.search.findBy("");
      },
      /**
       * Show the unloan screen of attached material scanner component in standalone mode.
       */
      _showUnloan() {
        this.$refs.materialsScanner.show("return");
      },
      /**
       * Show the overview profile screen of attached profile component in standalone mode.
       */
      _showProfile() {
        this.$refs.patronProfile.authenticate();
      },
      /**
       * Show the reservation cart.
       */
      _showCart() {
        this.$refs.materialsCart.show();
      },
      /**
       * Handler of rfid scanner inventory-updated event.
       *
       * @param {RFIDScannerInventory} inventory - The rfid scanner inventory.
       */
      _onScannerInventoryUpdate(inventory) {
        if (Object.keys(inventory).length !== 0) {
          this.$refs.materialsScanner.show("loan");
        }
      }
    },
    mounted() {
      this._initialize();

      if (this.$easyscreenConfig.get("enable.loan")) {
        this.$friendlyFrankScanner.on("inventory-updated", this._onScannerInventoryUpdate);
        this._onScannerInventoryUpdate(this.$friendlyFrankScanner.inventory);
      }
    },
    beforeDestroy() {
      if (this.$friendlyFrankScanner) {
        this.$friendlyFrankScanner.off("inventory-updated", this._onScannerInventoryUpdate);
      }

      this.$off("orientation-changed", this._adjustSize);
    },
    components: {
      "easyscreen-search": EasyscreenSearch,
      "easyscreen-search-light": EasyscreenSearchLight,
      "patron-profile": PatronProfile,
      "materials-scanner": MaterialsScanner,
      "easyscreen-materials-cart": EasyscreenMaterialsCart,
      "easyscreen-materials-cart-button": EasyscreenMaterialsCartButton,
      "modal-layout-navigation": ModalLayoutNavigation
    }
  };
</script>
